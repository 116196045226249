import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from '../Components/img/icon_1.svg';
import FormImg from '../Components/img/website_development_service.svg';

function ServicePage() {
    const [formData, setFormData] = useState({
        service: '',
        name: '',
        number: '',
        email: '',
        address: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch('http://localhost:5000/service-submit', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData),
                
            });

            if (response.ok) {
                console.log('Form submitted successfully');
            } else {
                console.log('Error submitting form');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };
    return (
        <>
            <div className="service-page-hero-main">
                <div className="service-page-hero-container">
                    {/* Update badge */}
                    <div className="service-page-hero-update-badge">Meta Ads</div>

                    {/* Main content */}
                    <div className="service-heading"> Build your online presence with our websites</div>
                    <div>Our web solutions are designed to enhance visibility, engage visitors, and convert leads into customers.</div>
                    <Link to="/contact">
                        <button className="service-button">Try It Now</button>
                    </Link>
                    <Link to="tel:+918433803555">
                        <button className="service-button2">+91 8433-803-555</button>
                    </Link>

                    {/* Logo */}
                    <div className="service-page-hero-logo">
                        <img src={logo} alt="Logo" />
                    </div>
                </div>
            </div>

            <section className="form-area">
                <div className="illustration">
                    <img src={FormImg} alt="illustration" />
                </div>
                <div className="form-container">
                    <form id="multiStepForm" onSubmit={handleSubmit}>

                        <div className="form-step active" id="formStep1">
                            <h2>Get Started Today</h2> <br />
                            <div className="error" id="formStep1Error"></div>
                            <select 
                            id="service" 
                            name="service" 
                            value={formData.service}
                            onChange={handleChange}
                            required>
                                <option value="">--Select Service--</option>
                                <option value="websiteDevelopment">Website Development</option>
                                <option value="ecommerceWebsite">E-commerce Website</option>
                                <option value="mobileDevelopment">Mobile Development</option>
                            </select>
                            <label htmlFor="name">Name</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                            <label htmlFor="number">Number</label>
                            <input
                                type="tel"
                                id="number"
                                name="number"
                                value={formData.number}
                                onChange={handleChange}
                                required
                            />
                            <label htmlFor="email">Email</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                            <label htmlFor="address">Address</label>
                            <input
                                type="text"
                                id="address"
                                name="address"
                                value={formData.address}
                                onChange={handleChange}
                                required
                            />
                            <button type="submit">Get Started</button>
                        </div>
                    </form>
                </div>
            </section>
            <div id="three-js-background"></div>
            <div className="useful">
                <h1>Why Your Business Needs Meta Ads</h1>
                <section className="useful-section">
                    <div className="info-box">
                        <i className="fas fa-users"></i>
                        <h3>Extensive Reach</h3>
                        <p>Meta Ads help you reach a vast audience across Facebook, Instagram, and the Audience Network, connecting you with potential customers wherever they are.</p>
                    </div>
                    <div className="info-box">
                        <i className="fas fa-bullseye"></i>
                        <h3>Precise Targeting</h3>
                        <p>Target specific demographics, interests, behaviors, and locations to ensure your ads are seen by the right people.</p>
                    </div>
                    <div className="info-box">
                        <i className="fas fa-rocket"></i>
                        <h3>Enhanced Engagement</h3>
                        <p>Engage users with visually appealing ads and interactive content, driving higher engagement and building brand awareness.</p>
                    </div>
                    <div className="info-box">
                        <i className="fas fa-chart-line"></i>
                        <h3>Performance Tracking</h3>
                        <p>Monitor and analyze ad performance with detailed metrics and insights to optimize your campaigns for better results.</p>
                    </div>
                </section>
            </div>
            <section className="process">
                <h1>Our Meta Ads Management Process</h1>
                <div className="process-container">
                    <div className="process-card">
                        <div className="process-number">1</div>
                        <i className="fas fa-search process-icon"></i>
                        <h2>Research</h2>
                        <p>Conduct market research to understand your target audience and competitors, and identify key opportunities for your Meta Ads campaigns.</p>
                    </div>
                    <div className="process-card">
                        <div className="process-number">2</div>
                        <i className="fas fa-cogs process-icon"></i>
                        <h2>Strategy</h2>
                        <p>Develop a tailored advertising strategy based on your business goals, target audience, and budget.</p>
                    </div>
                    <div className="process-card">
                        <div className="process-number">3</div>
                        <i className="fas fa-pen process-icon"></i>
                        <h2>Creation</h2>
                        <p>Design and create engaging ad content, including images, videos, and copy, to capture your audience's attention.</p>
                    </div>
                    <div className="process-card">
                        <div className="process-number">4</div>
                        <i className="fas fa-play process-icon"></i>
                        <h2>Launch</h2>
                        <p>Set up and launch your Meta Ads campaigns across Facebook, Instagram, and other platforms as needed.</p>
                    </div>
                    <div className="process-card">
                        <div className="process-number">5</div>
                        <i className="fas fa-eye process-icon"></i>
                        <h2>Monitoring</h2>
                        <p>Monitor the performance of your ads in real-time, tracking key metrics and making adjustments to improve effectiveness.</p>
                    </div>
                    <div className="process-card">
                        <div className="process-number">6</div>
                        <i className="fas fa-wrench process-icon"></i>
                        <h2>Optimization</h2>
                        <p>Optimize your campaigns based on performance data to enhance results and maximize your return on investment.</p>
                    </div>
                </div>
            </section>

            <div className="faq-container">
                <h1>Meta Ads Service - FAQ</h1>
                {[
                     {
                        question: "What services does 100Billion Tech offer for Meta Ads?",
                        answer: "We offer a full suite of Meta Ads management services, including ad strategy development, ad creation, audience targeting, campaign monitoring, and optimization. Our goal is to help you achieve your advertising objectives effectively."
                    },
                    {
                        question: "How much does it cost to run Meta Ads?",
                        answer: "The cost of Meta Ads varies based on factors such as competition, audience size, and your ad budget. You can set daily or lifetime budgets to control costs. We work with you to create a budget that aligns with your goals and maximizes results."
                    },
                    {
                        question: "How do you measure the success of a Meta Ads campaign?",
                        answer: "Success is measured through various metrics, including click-through rates (CTR), engagement rates, conversion rates, and return on ad spend (ROAS). We provide detailed reports to track these metrics and optimize your campaigns."
                    },
                    {
                        question: "How long does it take to see results from Meta Ads?",
                        answer: "Results can start to appear within a few days of launching your campaigns. However, achieving optimal performance and seeing significant results may take a few weeks as we continuously monitor and refine your ads."
                    },
                    {
                        question: "Can you help with both Facebook and Instagram ads?",
                        answer: "Yes, we manage ads across both Facebook and Instagram, as well as the Meta Audience Network. We’ll create and optimize ads tailored to each platform to reach your audience effectively."
                    },
                    {
                        question: "Do you offer Meta Ads consulting services?",
                        answer: "Yes, we provide consulting services to help you develop a strategic approach to Meta Ads, optimize existing campaigns, and achieve better results."
                    },
                    {
                        question: "How do you handle audience targeting for Meta Ads?",
                        answer: "We use detailed audience targeting options available on Meta platforms, including demographics, interests, behaviors, and custom audiences. This ensures your ads reach the most relevant users for your business."
                    },
                    {
                        question: "Can you help with ad copy and creative design?",
                        answer: "Yes, we create engaging ad copy and visually appealing creatives that resonate with your target audience. Our goal is to craft ads that drive engagement and conversions."
                    },
                    {
                        question: "What should I expect during the onboarding process?",
                        answer: "During onboarding, we’ll review your business objectives, target audience, and current advertising efforts. We’ll then develop a customized Meta Ads strategy and set up your campaigns to start driving results."
                    }
                ].map((faq, index) => (
                    <div className="faq-item" key={index}>
                        <div className="faq-question" onClick={(e) => toggleFaq(e.currentTarget)}>
                            {faq.question}
                            <i className="icon fas fa-chevron-down"></i>
                        </div>
                        <div className="faq-answer">{faq.answer}</div>
                    </div>
                ))}
            </div>
        </>
    );
}

// JS function for toggling FAQ
function toggleFaq(element) {
    const faqItems = document.querySelectorAll('.faq-item');

    faqItems.forEach(item => {
        if (item !== element.parentElement) {
            item.classList.remove('active');
        }
    });

    element.parentElement.classList.toggle('active');
}

export default ServicePage;
