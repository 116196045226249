import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import Header from './Components/Header';
import Home from './Components/Home';
import Footer from './Components/Footer';
import Chatbot from './Components/Chatbot';
import WebStore from './Components/Web-store';
import WebsiteDevelopment from './Components/Website_Development';
import ApplicationDevelopment from './Components/Application_Development';
import Ecommerce from './Components/Ecommerce';
import DigitalMarketing from './Components/DigitalMarketing';
import GoogleAds from './Components/Google_ads';
import MetaAds from './Components/Meta_ads';
import EcommercePricing from './Components/EcommercePricing';
import VendorPricing from './Components/VendorPricing';
import OTTPlatformPricing from './Components/OTTPlatformPricing';
import CRMPricing from './Components/CRMPricing';
import StaffPricing from './Components/StaffPricing';
import LoginPricing from './Components/LoginPricing';
import About from './Components/About';
import Contact from './Components/Contact';
import WhatsAppLink from './Components/Whatsapp';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import toastify CSS
import ScrollToTop from './Components/ScrollToTop';

const App = () => {
  return (
    <div className="App">
      <Header />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/website-development" element={<WebsiteDevelopment />} />
        <Route path="/application-development" element={<ApplicationDevelopment />} />
        <Route path="/digital-marketing" element={<DigitalMarketing />} />
        <Route path="/ecommerce" element={<Ecommerce />} />
        <Route path="/meta-ads" element={<MetaAds />} />
        <Route path="/Google-ads" element={<GoogleAds />} />
        <Route path="/web-Store" element={<WebStore />} />
        <Route path="/ecommerce-price" element={<EcommercePricing />} />
        <Route path="/vendor-price" element={<VendorPricing />} />
        <Route path='/ottplatform-price' element={<OTTPlatformPricing />} />
        <Route path='/crm-price' element={<CRMPricing />} />
        <Route path='/staff-price' element={<StaffPricing />} />
        <Route path='/login-price' element={<LoginPricing />} />
        <Route path='/about' element={<About />} />
        <Route path='/contact' element={<Contact />} />
      </Routes>
      <Footer />
      <Chatbot />
      <WhatsAppLink />
       {/* Toastify Container */}
       <ToastContainer />
    </div>
  );
};

export default App;
