// pages/Home.js
import React, { useState, useEffect } from 'react'; // Import useState and useEffect
import {Link} from 'react-router-dom'
import './CSS/Global.css';
import heroGif from '../Components/img/home_web.gif';
import Projects from '../Components/ProjectsCarousel';
import AboutImg from '../Components/img/hero-im.jpg'

const Counter = ({ target }) => {
    const [count, setCount] = useState(0);

    useEffect(() => {
        const increment = target / 200; // Adjust for speed
        const updateCount = () => {
            setCount(prevCount => {
                if (prevCount < target) {
                    return Math.ceil(prevCount + increment);
                }
                return target;
            });
        };

        const interval = setInterval(updateCount, 20);
        return () => clearInterval(interval);
    }, [target]);

    return <div className="counter-number">{count}</div>;
};

const Home = () => {
    return (
        <>
            <section className="hero-home">
                <section className="hero-content">
                    <section className="hero-details">
                        <div className="hero-h1">Your Vision, Our 100billion Codes</div>
                        <p>We specialize in delivering cutting-edge web and application development solutions,
                            helping businesses transform their ideas into digital realities.</p>
                        <section className="hero-details-btn">
                            <Link to='/contact'><button className="hero-button">Get Started Now</button></Link>
                        </section>
                    </section>
                    <section className="hero-img">
                        <div className="circle1"></div>
                        <img src={heroGif} alt="Hero Image" />
                        <div className="circle2"></div>
                    </section>

                </section>
            </section>

            {/* services */}
            <section className="service">
                <div className="service-head">
                    <h3>Our <span>Service</span></h3>
                </div>
                <section className="container">
                    <div className="card-wrap">
                        <div className="card-header one">
                            <i className="fas fa-chart-line"></i>
                        </div>
                        <div className="card-content">
                            <h1 className="card-title">Digital Marketing</h1>
                            <p className="card-text">Enhance your online presence with tailored marketing.</p>
                            <Link to='/digital-marketing' className="card-btn one">Expore details</Link>
                        </div>
                    </div>
                    <div className="card-wrap">
                        <div className="card-header two">
                            <i className="fas fa-shopping-cart"></i>
                        </div>
                        <div className="card-content">
                            <h1 className="card-title">E-commerce Website</h1>
                            <p className="card-text">Developing responsive and high-converting e-commerce.</p>
                            <Link to='/ecommerce' className="card-btn two">Expore details</Link>
                        </div>
                    </div>
                    <div className="card-wrap">
                        <div className="card-header three">
                            <i className="fab fa-google"></i>
                        </div>
                        <div className="card-content">
                            <h1 className="card-title">Google Ads</h1>
                            <p className="card-text">Maximize your ROI with targeted Google Ads campaigns.</p>
                            <Link to='/google-ads' className="card-btn three">Expore details</Link>
                        </div>
                    </div>
                    <div className="card-wrap">
                        <div className="card-header four">
                            <i className="fab fa-facebook"></i>
                        </div>
                        <div className="card-content">
                            <h1 className="card-title">Meta Ads</h1>
                            <p className="card-text">Reach a wider audience with effective Meta Ads strategies.</p>
                            <Link to='/meta-ads' className="card-btn four">Expore details</Link>
                        </div>
                    </div>
                    <div className="card-wrap">
                        <div className="card-header five">
                            <i className="fas fa-code"></i>
                        </div>
                        <div className="card-content">
                            <h1 className="card-title">Website Development</h1>
                            <p className="card-text">Creating custom, scalable, and robust websites.</p>
                            <Link to='/website-development' className="card-btn five">Expore details</Link>
                        </div>
                    </div>
                    <div className="card-wrap">
                        <div className="card-header six">
                            <i className="fas fa-mobile-alt"></i>
                        </div>
                        <div className="card-content">
                            <h1 className="card-title">Application Development</h1>
                            <p className="card-text">Building intuitive and powerful mobile applications.</p>
                            <Link to='/application-development' className="card-btn six">Expore details</Link>
                        </div>
                    </div>
                </section>
            </section>

            {/* projects */}
            <Projects />

            {/* about */}
            <section className="about">
                <section className="about-content">
                    <section className="about-img">
                        <section className="about-rect rect1">
                            <section className="rect-list">
                                <div className="icon">
                                    <i className="fa-solid fa-medal about-icon"></i>
                                </div>
                                <div className="detail">
                                    <h3>100 +</h3>
                                    <p>Customer satisfaction</p>
                                </div>
                            </section>
                        </section>
                        <section className="about-rect rect2">
                            <section className="rect-list">
                                <div className="icon">
                                    <i className="fa-solid fa-briefcase about-icon"></i>
                                </div>
                                <div className="detail">
                                    <h3>8 +</h3>
                                    <p>Years of establishment</p>
                                </div>
                            </section>
                        </section>
                        <section className="circle1"></section>
                        <section className="circle2"></section>
                        <section className="about-img-area">
                            <img src={AboutImg} alt="" />
                        </section>
                    </section>
                    <section className="about-details">
                        <h3>about <span>100billion Tech</span></h3>
                        <p>100Billion Tech is a leading technology solutions provider dedicated to transforming businesses
                            through innovative digital solutions. Our team of experts specializes in developing cutting-edge
                            web and mobile applications, ensuring that our clients stay ahead in the rapidly evolving
                            digital landscape.<br /><br />With a commitment to excellence, we deliver high-quality, scalable,
                            and secure technology solutions tailored to meet the unique needs of each client.
                        </p>
                        <ul>
                            <li>Innovative Digital Solutions</li>
                            <li>Tailored Client Services</li>
                            <li>Expertise and Excellence</li>
                        </ul>
                        <section className="about-details-btn">
                        <Link to='/about' className='stylish-button'>Know more</Link>
                        </section>
                    </section>
                </section>
            </section>

            {/* counter */}
            <section className="counter-section">
                <div className="counter-container">
                    <div className="counter-box">
                        <i className="fas fa-user"></i>
                        <Counter target={16} />
                        <div className="counter-text">Specialists</div>
                    </div>
                    <div className="counter-box">
                        <i className="fas fa-smile"></i>
                        <Counter target={56} />
                        <div className="counter-text">Happy Clients</div>
                    </div>
                    <div className="counter-box">
                        <i className="fas fa-briefcase"></i>
                        <Counter target={49} />
                        <div className="counter-text">Successful Cases</div>
                    </div>
                    <div className="counter-box">
                        <i className="fas fa-trophy"></i>
                        <Counter target={3} />
                        <div className="counter-text">Awards</div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Home;
