// WhatsAppLink.js
import React from 'react';
import '../Components/CSS/Global.css'

const WhatsAppLink = () => {
    return (
        <a 
            href="https://wa.me/8433803555?text=Hi%20I%20want%20service" 
            className="whatsapp-icon" 
            target="_blank" 
            rel="noopener noreferrer"
        >
            <i className="fab fa-whatsapp"></i>
        </a>
    );
};

export default WhatsAppLink;
