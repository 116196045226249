import React, { useState, useEffect, useRef } from 'react';
import '../Components/CSS/Global.css';
import { AiOutlineClose, AiOutlineMessage, AiOutlineSend, AiOutlineRobot, AiOutlineUser } from 'react-icons/ai';

const Chatbot = () => {
  const [formData, setFormData] = useState({
    form_type: 'chatbot',
    name: '',
    gmail: '',
    phone: '',
    selected_service: '',
  });

  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([{ sender: 'bot', text: 'Hello! How can I assist you today?' }]);
  const [step, setStep] = useState(1);
  const [isTyping, setIsTyping] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const messagesEndRef = useRef(null);

  const services = [
    'Website Development',
    'Application Development',
    'Digital Marketing',
    'E-Commerce website',
    'Google ads',
    'Meta ads'
  ];

  const toggleChat = () => setIsOpen(!isOpen);

  const handleChange = (name, value) => {
    setFormData((prevData) => {
      const updatedData = { ...prevData, [name]: value };
      console.log('Updated formData:', updatedData);
      return updatedData;
    });
  };

  const handleServiceClick = (service) => {
    handleChange('selected_service', service);
    addMessage('user', service);
    setIsTyping(true);
    setTimeout(() => {
      addMessage('bot', `Great choice! May I know your name?`);
      setStep(2);
      setIsTyping(false);
    }, 100);
  };

  const handleUserInfo = (info) => {
    if (step === 2) {
      handleChange('name', info);
    } else if (step === 3) {
      // Email validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(info)) {
        addMessage('bot', 'Please enter a valid email address.');
        setIsTyping(false);
        return;
      }
      handleChange('gmail', info);
      console.log("Gmail captured:", info);
    } else if (step === 4) {
      // Phone number validation
      const phoneRegex = /^\d{10}$/;
      if (!phoneRegex.test(info)) {
        addMessage('bot', 'Please enter a valid 10-digit contact number.');
        setIsTyping(false);
        return;
      }
      handleChange('phone', info);
    }

    addMessage('user', info);
    setInputValue('');
    setIsTyping(true);

    if (step === 2) {
      setTimeout(() => {
        addMessage('bot', `Thanks, ${info} Could I get your email?`);
        setStep(3);
        setIsTyping(false);
      }, 1000);
    } else if (step === 3) {
      setTimeout(() => {
        addMessage('bot', `Could you provide your contact number?`);
        setStep(4);
        setIsTyping(false);
      }, 1000);
    } else if (step === 4) {
      setTimeout(() => {
        addMessage('bot', `Thank you for the information! Please review your details.`);
        setStep(5);
        setIsTyping(false);
      }, 1000);
    }
  };


  const addMessage = (sender, text) => {
    setMessages((prevMessages) => [...prevMessages, { sender, text }]);
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleSubmit = async () => {
    const updatedFormData = { ...formData };
    console.log("Submitting Form Data: ", updatedFormData);
    try {
      const response = await fetch('http://localhost:5000/chatbot-submit', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(updatedFormData),
      });

      if (response.ok) {
        console.log('Form submitted successfully');
        addMessage('bot', 'Thanks for submitting! Our team will contact you shortly.');

        setFormData({
          form_type: 'chatbot',
          name: '',
          gmail: '',
          phone: '',
          selected_service: '',
        });

        setStep(1);
        setMessages([{ sender: 'bot', text: 'Hello! How can I assist you today?' }]);
      } else {
        console.log('Error submitting form');
        addMessage('bot', 'There was an error submitting your information. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      addMessage('bot', 'There was an error submitting your information. Please try again.');
    }
  };

  const handleRestartOrSubmit = (choice) => {
    if (choice === 'No') {
      // Submit data first
      handleSubmit();

      // Wait for the data submission, then close the chatbot
      setTimeout(() => {
        setIsOpen(false);
      }, 1000);
    } else {
      // Proceed to restart the chatbot flow (step 1)
      handleSubmit();
      setStep(1);
      setMessages([{ sender: 'bot', text: 'Hello! How can I assist you today?' }]);
    }
  };

  return (
    <div className="btc-chatbot">
      <button className={`btc-chat-toggle ${isOpen ? 'btc-close' : 'btc-open'}`} onClick={toggleChat}>
        {isOpen ? (
          <AiOutlineClose size={20} />
        ) : (
          <>
            <AiOutlineMessage size={20} />
            <span style={{ marginLeft: '8px' }}>Chat With Us</span>
          </>
        )}
      </button>

      {isOpen && (
        <div className="btc-chatbox">
          <div className="btc-chat-header"><i className="fa-solid fa-headset"></i>100Billion Tech Support</div>
          <div className="btc-chat-messages">
            {messages.map((msg, index) => (
              <div key={index} className={`btc-message ${msg.sender}`}>
                {msg.sender === 'bot' ? <AiOutlineRobot /> : <AiOutlineUser />}
                <span>{msg.text}</span>
              </div>
            ))}
            {isTyping && (
              <div className="btc-message bot btc-typing-indicator">
                <AiOutlineRobot /> <span>Typing...</span>
              </div>
            )}
            <div ref={messagesEndRef}></div>
          </div>

          {step === 1 && (
            <div className="btc-chat-options">
              {services.map((service, index) => (
                <button key={index} onClick={() => handleServiceClick(service)} className="btc-service-button">
                  {service}
                </button>
              ))}
            </div>
          )}

          {step > 1 && step < 5 && (
            <div className="btc-chat-input">
              <input
                type="text"
                placeholder={
                  step === 2 ? 'Enter your name' :
                    step === 3 ? 'Enter email (e.g., example@domain.com)' :
                      step === 4 ? 'Enter 10-digit contact number' : ''
                }
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && e.target.value.trim()) {
                    handleUserInfo(e.target.value.trim());
                  }
                }}
              />
              <button onClick={() => inputValue.trim() && handleUserInfo(inputValue)}>
                <AiOutlineSend />
              </button>
            </div>
          )}

          {step === 5 && (
            <div className="btc-chat-review">
              <div className="btc-chat-options">
                <p>Do you need any other assistance?</p>
                <div className="btc-chat-button-yes-no">
                  <button onClick={() => handleRestartOrSubmit('Yes')} className="btc-service-button">Yes</button>
                  <button onClick={() => handleRestartOrSubmit('No')} className="btc-service-button">No</button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Chatbot;
