import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import '../Components/CSS/Global.css';

// Import images for each card
import DDPlayer from '../Components/img/DD Player.png';
import BigBullPaisawala from '../Components/img/BigBull Paisewala.png';
import SmartHotelEquipment from '../Components/img/Smart Hotel Equipments.png';
import SohanRockstar from '../Components/img/Soham Rockstar Entertainment.png';
import VisionMotion from '../Components/img/vison motion.png';
import SkateHouse from '../Components/img/Skate House 2.png';
import DDPlayer2 from '../Components/img/dd_player.jpg';
import BigBullPaisawala2 from '../Components/img/bigbull.jpg';
import billiontech from '../Components/img/100BILLION_LOGO slider.jpg';
import DDPLogo from '../Components/img/DDP_B.jpg';
import iamPlex from '../Components/img/IAMPLEX_LOGO.jpg';
import Padma from '../Components/img/Padma_logo.jpg';
import SHELogo from '../Components/img/SHE_LOGO_Name 2.jpg';
import poster1 from '../Components/img/p1.jpg';
import poster2 from '../Components/img/p2.jpg';
import poster3 from '../Components/img/p3.jpg';
import poster4 from '../Components/img/p4.jpg';
import poster5 from '../Components/img/p5.jpg';
import digital1 from '../Components/img/DDP_B.jpg';
import digital2 from '../Components/img/BigBull Paisewala.png';
import digital3 from '../Components/img/obk.jpg';
import digital4 from '../Components/img/SHE_LOGO_Name 2.jpg';

const CardSlider = () => {
  const swiperRef = useRef(null);
  const [activeTab, setActiveTab] = useState('website');

  const goPrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const goNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  // Render cards with individual divs based on the active tab
  const renderCards = () => {
    switch (activeTab) {
      case 'website':
        return (
          <>
            <SwiperSlide>
              <div className="card">
                <img src={DDPlayer} alt="DD Player" className="card-image" />
                <button onClick={() => window.open("https://divyadrishtiplayer.com/", "_blank", "noopener,noreferrer")} className="navigate-button">
                  DD Player
                </button>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card">
                <img src={BigBullPaisawala} alt="Big Bull Paisawala" className="card-image" />
                <button onClick={() => window.open("https://bigbullpaisewala.com/", "_blank", "noopener,noreferrer")} className="navigate-button">
                  Big Bull Paisawala
                </button>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card">
                <img src={SmartHotelEquipment} alt="Smart Hotel Equipment" className="card-image" />
                <button onClick={() => window.open("https://smarthotelequipment.com/", "_blank", "noopener,noreferrer")} className="navigate-button">
                  Smart Hotel Equipment
                </button>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card">
                <img src={SohanRockstar} alt="Sohan Rockstar" className="card-image" />
                <button onClick={() => window.open("https://sohamrockstar.com/", "_blank", "noopener,noreferrer")} className="navigate-button">
                  Soham Rockstar Entertainment
                </button>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card">
                <img src={VisionMotion} alt="Vision Motion" className="card-image" />
                <button onClick={() => window.open("https://visionmotion.com/", "_blank", "noopener,noreferrer")} className="navigate-button">
                  Vision Motion
                </button>
              </div>
            </SwiperSlide>
            
          </>
        );
      case 'ecommerce':
        return (
          <>
            <SwiperSlide>
              <div className="card">
                <img src={SmartHotelEquipment} alt="Smart Hotel Equipment Products" className="card-image" />
                <button onClick={() => window.open("https://products.smarthotelequipment.com/", "_blank", "noopener,noreferrer")} className="navigate-button">
                  Smart Hotel Equipment Products
                </button>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card">
                <img src={SkateHouse} alt="Skate House" className="card-image" />
                <button onClick={() => window.open("https://shop.skatehouse.in/", "_blank", "noopener,noreferrer")} className="navigate-button">
                  Skate House
                </button>
              </div>
            </SwiperSlide>
          </>
        );
      case 'applications':
        return (
          <>
            <SwiperSlide>
              <div className="card">
                <img src={DDPlayer2} alt="DD Player App" className="card-image" />
                <button onClick={() => window.open("https://play.google.com/store/apps/details?id=net.digital.divyadrishtiplayer", "_blank", "noopener,noreferrer")} className="navigate-button">
                  DD Player App
                </button>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card">
                <img src={BigBullPaisawala2} alt="Big Bull Paisawala App" className="card-image" />
                <button onClick={() => window.open("https://play.google.com/store/apps/details?id=com.bigbullpaisewala.com", "_blank", "noopener,noreferrer")} className="navigate-button">
                  Big Bull Paisawala App
                </button>
              </div>
            </SwiperSlide>
          </>
        );
      case 'logo':
        return (
          <>
            <SwiperSlide>
              <div className="card">
                <img src={billiontech} alt="100 Billion Tech Logo" className="card-image" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card">
                <img src={DDPLogo} alt="DDP Logo" className="card-image" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card">
                <img src={iamPlex} alt="IAMPLEX Logo" className="card-image" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card">
                <img src={Padma} alt="Padma Logo" className="card-image" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card">
                <img src={SHELogo} alt="SHE Logo" className="card-image" />
              </div>
            </SwiperSlide>
          </>
        );
      case 'poster':
        return (
          <>
            <SwiperSlide>
              <div className="card">
                <img src={poster1} alt="Poster 1" className="card-image" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card">
                <img src={poster2} alt="Poster 2" className="card-image" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card">
                <img src={poster3} alt="Poster 3" className="card-image" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card">
                <img src={poster4} alt="Poster 4" className="card-image" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card">
                <img src={poster5} alt="Poster 5" className="card-image" />
              </div>
            </SwiperSlide>
          </>
        );
      case 'digital':
        return (
          <>
            <SwiperSlide>
              <div className="card">
                <img src={digital1} alt="Digital Design 1" className="card-image" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card">
                <img src={digital2} alt="Digital Design 2" className="card-image" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card">
                <img src={digital3} alt="Digital Design 3" className="card-image" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card">
                <img src={digital4} alt="Digital Design 4" className="card-image" />
              </div>
            </SwiperSlide>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="card-slider">
      <h2 className="heading">Our Projects</h2>
      <div className="tabs">
        <button className={`tab-button ${activeTab === 'website' ? 'active' : ''}`} onClick={() => setActiveTab('website')}>Website</button>
        <button className={`tab-button ${activeTab === 'ecommerce' ? 'active' : ''}`} onClick={() => setActiveTab('ecommerce')}>E-commerce</button>
        <button className={`tab-button ${activeTab === 'applications' ? 'active' : ''}`} onClick={() => setActiveTab('applications')}>Applications</button>
        <button className={`tab-button ${activeTab === 'logo' ? 'active' : ''}`} onClick={() => setActiveTab('logo')}>Logo</button>
        <button className={`tab-button ${activeTab === 'poster' ? 'active' : ''}`} onClick={() => setActiveTab('poster')}>Poster</button>
        <button className={`tab-button ${activeTab === 'digital' ? 'active' : ''}`} onClick={() => setActiveTab('digital')}>Digital</button>
      </div>
      <div className="swiper-navigation">
        <button className="custom-prev-button" onClick={goPrev}><i className="fa-solid fa-chevron-left"></i></button>
        <Swiper
          slidesPerView={4}
          spaceBetween={20}
          ref={swiperRef}
          breakpoints={{
            320: { slidesPerView: 1, spaceBetween: 10 },
            640: { slidesPerView: 2, spaceBetween: 15 },
            1024: { slidesPerView: 3, spaceBetween: 20 },
            1280: { slidesPerView: 4, spaceBetween: 20 },
          }}
        >
          {renderCards()}
        </Swiper>
        <button className="custom-next-button" onClick={goNext}><i className="fa-solid fa-chevron-right"></i></button>
      </div>
    </div>
  );
};

export default CardSlider;
