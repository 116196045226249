import React, { useState, useEffect } from 'react';
import '../Components/CSS/Global.css';

const features = [
    { id: 1, name: "Employee Attendance Tracking", price: 100 },
    { id: 2, name: "Payroll Management", price: 150 },
    { id: 3, name: "Task Assignment & Monitoring", price: 120 },
    { id: 4, name: "Employee Database Management", price: 90 },
    { id: 5, name: "Performance Reviews", price: 80 },
    { id: 6, name: "Shift Scheduling", price: 70 },
    { id: 7, name: "Employee Self-Service Portal", price: 60 },
    { id: 8, name: "Document Management", price: 110 },
    { id: 9, name: "Training and Development", price: 95 },
    { id: 10, name: "Reports & Analytics", price: 130 },
];

const StaffPricing = () => {
    const [selectedFeatures, setSelectedFeatures] = useState([]);

    useEffect(() => {
        const savedFeatures = JSON.parse(localStorage.getItem("selectedFeatures")) || [];
        setSelectedFeatures(savedFeatures);
    }, []);

    useEffect(() => {
        localStorage.setItem("selectedFeatures", JSON.stringify(selectedFeatures));
    }, [selectedFeatures]);

    const toggleFeature = (feature) => {
        setSelectedFeatures(prevSelected => {
            if (prevSelected.includes(feature.id)) {
                return prevSelected.filter(id => id !== feature.id);
            } else {
                return [...prevSelected, feature.id];
            }
        });
    };

    const totalAmount = selectedFeatures.reduce((total, featureId) => {
        const feature = features.find(f => f.id === featureId);
        return total + (feature ? feature.price : 0);
    }, 0);

    return (
        <>
            <div className="pricing">
            <h2>Select Services</h2>
                {features.map(feature => (
                    <div key={feature.id} className="pricing-list">
                        <div className="price-title">
                            {feature.name}
                        </div>
                        <div className="price-toggle-container">
                            <div className="price-amount">
                                ₹{feature.price}
                            </div>
                            <div className="toggle-container">
                                <label className="switch">
                                    <input 
                                        type="checkbox" 
                                        checked={selectedFeatures.includes(feature.id)} 
                                        onChange={() => toggleFeature(feature)} 
                                    />
                                    <span className="slider"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <div className="pricing-total">
                <h2>Total: ₹{totalAmount}</h2>
                <button>Proceed</button>
            </div>
        </>
    );
};

export default StaffPricing;
