import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from '../Components/img/icon_1.svg';
import FormImg from '../Components/img/Digital_Marketing.gif';

function ServicePage() {
    const [formData, setFormData] = useState({
        service: '',
        name: '',
        number: '',
        email: '',
        address: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch('http://localhost:5000/service-submit', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData),
                
            });

            if (response.ok) {
                console.log('Form submitted successfully');
            } else {
                console.log('Error submitting form');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };
    return (
        <>
            <div className="service-page-hero-main">
                <div className="service-page-hero-container">
                    {/* Update badge */}
                    <div className="service-page-hero-update-badge">Digital Marketing</div>

                    {/* Main content */}
                    <div className="service-heading">Growth with Digital Marketing</div>
                    <div>Drive your business forward with data-driven digital marketing strategies that boost visibility, engagement, and conversions.</div>

                    <Link to="/contact">
                        <button className="service-button">Try It Now</button>
                    </Link>
                    <Link to="tel:+918433803555">
                        <button className="service-button2">+91 8433-803-555</button>
                    </Link>

                    {/* Logo */}
                    <div className="service-page-hero-logo">
                        <img src={logo} alt="Logo" />
                    </div>
                </div>
            </div>

            <section className="form-area">
                <div className="illustration">
                    <img src={FormImg} alt="illustration" />
                </div>
                <div className="form-container">
                    <form id="multiStepForm" onSubmit={handleSubmit}>
                        <div className="form-step active" id="formStep1">
                            <h2>Get Started Today</h2> <br />
                            <div className="error" id="formStep1Error"></div>
                            <select 
                            id="service" 
                            name="service" 
                            value={formData.service}
                            onChange={handleChange}
                            required>
                                <option value="">--Select Service--</option>
                                <option value="websiteDevelopment">Website Development</option>
                                <option value="ecommerceWebsite">E-commerce Website</option>
                                <option value="mobileDevelopment">Mobile Development</option>
                            </select>
                            <label htmlFor="name">Name</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                            <label htmlFor="number">Number</label>
                            <input
                                type="tel"
                                id="number"
                                name="number"
                                value={formData.number}
                                onChange={handleChange}
                                required
                            />
                            <label htmlFor="email">Email</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                            <label htmlFor="address">Address</label>
                            <input
                                type="text"
                                id="address"
                                name="address"
                                value={formData.address}
                                onChange={handleChange}
                                required
                            />
                            <button type="submit">Get Started</button>
                        </div>
                        <div className="form-step" id="formStep2">
                            <h2>Step 2: Select Website Services</h2>
                            <div className="error" id="formStep2Error"></div>
                            <label><input type="checkbox" name="service" value="websiteDesign" /> Website Design</label>
                            <label><input type="checkbox" name="service" value="websiteMaintenance" /> Website Maintenance</label>
                            <label><input type="checkbox" name="service" value="seoOptimization" /> SEO Optimization</label>
                            <label><input type="checkbox" name="service" value="contentCreation" /> Content Creation</label>
                            <button type="button" id="backButton">Back</button>
                            <button type="submit">Submit</button>
                        </div>
                    </form>
                </div>
            </section>

            <div id="three-js-background"></div>
            <div className="useful">
                <h1>Why Your Business Needs Digital Marketing</h1>
                <section className="useful-section">
                    {[
                        { icon: "fas fa-search", title: "Search Engine Optimization (SEO)", description: "Improve your website’s visibility on search engines to attract more organic traffic and increase rankings." },
                        { icon: "fas fa-chart-line", title: "Pay-Per-Click Advertising (PPC)", description: "Target your audience with paid ads on search engines and social media, ensuring instant visibility and measurable results." },
                        { icon: "fas fa-dollar-sign", title: "Social Media Marketing (SMM)", description: "Build brand awareness and engage with your audience on platforms like Facebook, Instagram, Twitter, and LinkedIn." },
                        { icon: "fas fa-pencil-ruler", title: "Content Marketing", description: "Create and distribute valuable content that educates, engages, and converts your audience into customers." },
                        // Add more info-box items as needed
                    ].map((box, index) => (
                        <div className="info-box" key={index}>
                            <i className={box.icon}></i>
                            <h3>{box.title}</h3>
                            <p>{box.description}</p>
                        </div>
                    ))}
                </section>
            </div>

            <section class="process">
                <h1>Our Digital Marketing Process</h1>
                <div class="process-container">
                    <div class="process-card">
                        <div class="process-number">1</div>
                        <i class="fas fa-search process-icon"></i>
                        <h2>Research</h2>
                        <p>Conduct market research to understand your industry, competitors, and target audience.</p>
                    </div>
                    <div class="process-card">
                        <div class="process-number">2</div>
                        <i class="fa-solid fa-bullseye process-icon"></i>
                        <h2>Strategy</h2>
                        <p>Develop a comprehensive digital marketing strategy that aligns with your business goals and target audience.</p>
                    </div>
                    <div class="process-card">
                        <div class="process-number">3</div>
                        <i class="fas fa-pen process-icon"></i>
                        <h2>Implementation</h2>
                        <p>Execute the strategy across various channels such as SEO, PPC, social media, and content marketing.</p>
                    </div>
                    <div class="process-card">
                        <div class="process-number">4</div>
                        <i class="fa-solid fa-desktop process-icon"></i>
                        <h2>Monitoring</h2>
                        <p>Monitor campaign performance in real-time to ensure that your marketing efforts are on track.</p>
                    </div>
                    <div class="process-card">
                        <div class="process-number">5</div>
                        <i class="fas fa-chart-line process-icon"></i>
                        <h2>Analysis</h2>
                        <p>Analyze data to evaluate the effectiveness of your campaigns and identify areas for improvement.</p>
                    </div>
                    <div class="process-card">
                        <div class="process-number">6</div>
                        <i class="fas fa-sync-alt process-icon"></i>
                        <h2>Optimization</h2>
                        <p>Adjust and optimize your strategies based on performance insights to maximize results and ROI.</p>
                    </div>
                </div>
            </section>


            <div className="faq-container">
                <h1>Digital Marketing Service - FAQ</h1>
                {[
                     {
                        question: "What services does 100Billion Tech offer in digital marketing?",
                        answer: "We offer a wide range of digital marketing services, including search engine optimization (SEO), pay-per-click (PPC) advertising, social media marketing, content marketing, email marketing, and more. Our services are designed to enhance your online presence and drive business growth."
                    },
                    {
                        question: "How long does it take to see results from digital marketing?",
                        answer: "The timeframe for seeing results varies depending on the type of digital marketing strategy and your business goals. SEO typically takes several months to show significant results, while PPC and social media marketing can yield quicker outcomes."
                    },
                    {
                        question: "How do you measure the success of digital marketing campaigns?",
                        answer: "Success is measured using key performance indicators (KPIs) such as website traffic, conversion rates, engagement metrics, and return on investment (ROI). We provide detailed analytics and reports to track these metrics and gauge the effectiveness of your campaigns."
                    },
                    {
                        question: "Can you help with both organic and paid digital marketing?",
                        answer: "Yes, we specialize in both organic digital marketing strategies, such as SEO and content marketing, and paid strategies like PPC advertising and social media ads. We’ll develop a balanced approach that aligns with your business objectives."
                    },
                    {
                        question: "Do you offer digital marketing consulting services?",
                        answer: "Yes, we offer digital marketing consulting services to help you develop effective strategies, optimize your existing campaigns, and achieve your marketing goals. Our consultants provide expert guidance tailored to your specific needs."
                    },
                    {
                        question: "How do you handle content creation for digital marketing?",
                        answer: "We create high-quality, engaging content tailored to your audience and marketing goals. This includes blog posts, social media updates, videos, infographics, and more, designed to drive traffic and enhance your online presence."
                    },
                    {
                        question: "How do you approach social media marketing?",
                        answer: "We develop and execute social media marketing strategies that include content creation, audience engagement, and performance tracking. We tailor our approach to each platform to effectively reach and connect with your target audience."
                    },
                    {
                        question: "What should I expect during the onboarding process?",
                        answer: "During onboarding, we’ll review your business goals, target audience, and current marketing efforts. We’ll then create a customized digital marketing strategy and begin implementing it to drive results for your business."
                    }
                ].map((faq, index) => (
                    <div className="faq-item" key={index}>
                        <div className="faq-question" onClick={(e) => toggleFaq(e.currentTarget)}>
                            {faq.question}
                            <i className="icon fas fa-chevron-down"></i>
                        </div>
                        <div className="faq-answer">{faq.answer}</div>
                    </div>
                ))}
            </div>
        </>
    );
}

// JS function for toggling FAQ
function toggleFaq(element) {
    const faqItems = document.querySelectorAll('.faq-item');

    faqItems.forEach(item => {
        if (item !== element.parentElement) {
            item.classList.remove('active');
        }
    });

    element.parentElement.classList.toggle('active');
}

export default ServicePage;
