import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from '../Components/img/icon_1.svg';
import FormImg from '../Components/img/website_development_service.svg';

function ServicePage() {
    const [formData, setFormData] = useState({
        service: '',
        name: '',
        number: '',
        email: '',
        address: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch('http://localhost:5000/service-submit', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                console.log('Form submitted successfully');
            } else {
                console.log('Error submitting form');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <>
            <div className="service-page-hero-main">
                <div className="service-page-hero-container">
                    <div className="service-page-hero-update-badge">Website Development</div>
                    <div className="service-heading">Build your online presence</div>
                    <div>Building dynamic, responsive websites that drive engagement and elevate your brand.</div>

                    <Link to="/contact">
                        <button className="service-button">Try It Now</button>
                    </Link>
                    <Link to="tel:+918433803555">
                        <button className="service-button2">+91 8433-803-555</button>
                    </Link>

                    <div className="service-page-hero-logo">
                        <img src={logo} alt="Logo" />
                    </div>
                </div>
            </div>

            <section className="form-area">
                <div className="illustration">
                    <img src={FormImg} alt="illustration" />
                </div>
                <div className="form-container">
                    <form onSubmit={handleSubmit}>
                        <div className="form-step active">
                            <h2>Get Started Today</h2>
                            <br />
                            <div className="error" id="formStep1Error"></div>
                            <label htmlFor="service">Service</label>
                            <select
                                id="service"
                                name="service"
                                value={formData.service}
                                onChange={handleChange}
                                required
                            >
                                {/* <option value="">--Select Service--</option> */}
                                <option value="websiteDevelopment">Website Development</option>
                                <option value="ecommerceWebsite">E-commerce Website</option>
                                <option value="mobileDevelopment">Mobile Development</option>
                            </select>
                            <label htmlFor="name">Name</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                            <label htmlFor="number">Number</label>
                            <input
                                type="tel"
                                id="number"
                                name="number"
                                value={formData.number}
                                onChange={handleChange}
                                required
                            />
                            <label htmlFor="email">Email</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                            <label htmlFor="address">Address</label>
                            <input
                                type="text"
                                id="address"
                                name="address"
                                value={formData.address}
                                onChange={handleChange}
                                required
                            />
                            <button type="submit">Get Started</button>
                        </div>
                    </form>
                </div>
            </section>

            <div id="three-js-background"></div>
            <div className="useful">
                <h1>Why Every Business Needs a Website</h1>
                <section className="useful-section">
                    <div className="info-box">
                        <i className="fas fa-globe"></i>
                        <h3>Online Presence</h3>
                        <p>Your website acts as a 24/7 storefront, providing customers with access to your business anytime, anywhere.</p>
                    </div>
                    <div className="info-box">
                        <i className="fas fa-shield-alt"></i>
                        <h3>Credibility</h3>
                        <p>A professional website adds credibility to your business, making it easier to build trust with potential customers.</p>
                    </div>
                    <div className="info-box">
                        <i className="fas fa-user-friends"></i>
                        <h3>Customer Engagement</h3>
                        <p>Your website serves as a platform to engage with customers, gather feedback, and offer support.</p>
                    </div>
                    <div className="info-box">
                        <i className="fas fa-lightbulb"></i>
                        <h3>Innovation</h3>
                        <p>Your website allows you to showcase your latest products, services, and innovations to your audience.</p>
                    </div>
                </section>
            </div>

            <section className="process">
                <h1>Our Website Development Process</h1>
                <div className="process-container">
                    <div className="process-card">
                        <div className="process-number">1</div>
                        <i className="fas fa-project-diagram process-icon"></i>
                        <h2>Planning</h2>
                        <p>Define the scope, goals, and timeline of the project.</p>
                    </div>
                    <div className="process-card">
                        <div className="process-number">2</div>
                        <i className="fas fa-pencil-ruler process-icon"></i>
                        <h2>Design</h2>
                        <p>Create wireframes and mockups of the website layout.</p>
                    </div>
                    <div className="process-card">
                        <div className="process-number">3</div>
                        <i className="fas fa-code process-icon"></i>
                        <h2>Development</h2>
                        <p>Build the website using HTML, CSS, and JavaScript.</p>
                    </div>
                    <div className="process-card">
                        <div className="process-number">4</div>
                        <i className="fas fa-bug process-icon"></i>
                        <h2>Testing</h2>
                        <p>Test the website for usability and fix any bugs.</p>
                    </div>
                    <div className="process-card">
                        <div className="process-number">5</div>
                        <i className="fas fa-rocket process-icon"></i>
                        <h2>Deployment</h2>
                        <p>Launch the website and monitor its performance.</p>
                    </div>
                    <div className="process-card">
                        <div className="process-number">6</div>
                        <i className="fas fa-chart-line process-icon"></i>
                        <h2>Maintenance</h2>
                        <p>Update and maintain the website to ensure its continued success.</p>
                    </div>
                </div>
            </section>


            <div className="faq-container">
                <h1>Web Development Service - FAQ</h1>
                {[{
                    question: "What services does 100Billion Tech provide in web development?",
                    answer: "We offer a comprehensive range of web development services, including custom website design, e-commerce solutions, CMS development, and responsive design. Our team specializes in creating user-friendly, high-performance websites tailored to your business needs."
                },
                {
                    question: "How long does it take to develop a website?",
                    answer: "The timeline for website development varies depending on the complexity and specific requirements of the project. On average, a standard business website can take 4-6 weeks from concept to launch, while more complex sites like e-commerce platforms may take 8-12 weeks."
                },
                {
                    question: "Do you offer ongoing maintenance and support?",
                    answer: "Yes, 100Billion Tech provides ongoing maintenance and support services to ensure your website remains secure, up-to-date, and fully operational. We offer various support packages tailored to your specific needs."
                },
                {
                    question: "Can you help with SEO and online marketing?",
                    answer: "Absolutely! Our team includes SEO specialists who can optimize your website to improve search engine rankings. We also offer online marketing services, including content marketing, social media management, and pay-per-click advertising."
                },
                {
                    question: "What technologies do you use for web development?",
                    answer: "Our team is proficient in a wide range of technologies, including HTML, CSS, JavaScript, React, Angular, Node.js, PHP, Python, and various content management systems like WordPress, Drupal, and Magento. We choose the best tools and technologies based on your project's specific requirements."
                },
                {
                    question: "Do you offer custom web applications?",
                    answer: "Yes, we specialize in developing custom web applications tailored to your business needs. Whether you need a custom CRM, e-commerce platform, or an internal business tool, our team can build a solution that fits your requirements."
                },
                {
                    question: "How much does it cost to develop a website?",
                    answer: "The cost of developing a website depends on various factors such as complexity, features, design requirements, and timeline. We provide custom quotes based on your specific needs. Contact us for a detailed proposal."
                },
                {
                    question: "Will my website be mobile-friendly?",
                    answer: "Yes, all websites we develop are fully responsive and optimized for mobile devices. We ensure that your site delivers an excellent user experience across all screen sizes, from desktops to smartphones."
                },
                {
                    question: "Can you integrate third-party services into my website?",
                    answer: "Yes, we can integrate various third-party services and APIs into your website, such as payment gateways, social media platforms, CRM systems, and analytics tools. Our team ensures seamless integration to enhance your website's functionality."
                },
                {
                    question: "What is your process for starting a new project?",
                    answer: "Our process starts with an initial consultation to understand your project goals and requirements. We then proceed with planning, design, development, testing, and finally, launch. Throughout the project, we maintain close communication with you to ensure your vision is realized."
                }
                    // ...more FAQs
                ].map((faq, index) => (
                    <div className="faq-item" key={index}>
                        <div className="faq-question" onClick={(e) => toggleFaq(e.currentTarget)}>
                            {faq.question}
                            <i className="icon fas fa-chevron-down"></i>
                        </div>
                        <div className="faq-answer">{faq.answer}</div>
                    </div>
                ))}
            </div>
        </>
    );
}

// JS function for toggling FAQ
function toggleFaq(element) {
    const faqItems = document.querySelectorAll('.faq-item');

    faqItems.forEach(item => {
        if (item !== element.parentElement) {
            item.classList.remove('active');
        }
    });

    element.parentElement.classList.toggle('active');
}

export default ServicePage;
