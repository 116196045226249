import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from '../Components/img/icon_1.svg';
import FormImg from '../Components/img/E-Commerce.svg';

function ServicePage() {
    const [formData, setFormData] = useState({
        service: '',
        name: '',
        number: '',
        email: '',
        address: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch('http://localhost:5000/service-submit', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData),
                
            });

            if (response.ok) {
                console.log('Form submitted successfully');
            } else {
                console.log('Error submitting form');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };
    return (
        <>
            <div className="service-page-hero-main">
                <div className="service-page-hero-container">
                    {/* Update badge */}
                    <div className="service-page-hero-update-badge">E-Commerce Development</div>

                    {/* Main content */}
                    <div className="service-heading">Growth with E-Commerce Solutions</div>
                    <div>Transform your business with robust e-commerce platforms that drive sales, engagement, and customer satisfaction.</div>

                    <Link to="/contact">
                        <button className="service-button">Get Started</button>
                    </Link>
                    <Link to="tel:+918433803555">
                        <button className="service-button2">+91 8433-803-555</button>
                    </Link>

                    {/* Logo */}
                    <div className="service-page-hero-logo">
                        <img src={logo} alt="Logo" />
                    </div>
                </div>
            </div>

            <section className="form-area">
                <div className="illustration">
                    <img src={FormImg} alt="E-commerce illustration" />
                </div>
                <div className="form-container">
                    <form id="multiStepForm" onSubmit={handleSubmit}>
                        <div className="form-step active" id="formStep1">
                            <h2>Get Started Today</h2> <br />
                            <div className="error" id="formStep1Error"></div>
                            <select 
                            id="service" 
                            name="service"
                            value={formData.service}
                            onChange={handleChange}
                            required>
                                <option value="">--Select Service--</option>
                                <option value="websiteDevelopment">Website Development</option>
                                <option value="ecommerceWebsite">E-commerce Website</option>
                                <option value="mobileDevelopment">Mobile Development</option>
                            </select>
                            <label htmlFor="name">Name</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                            <label htmlFor="number">Number</label>
                            <input
                                type="tel"
                                id="number"
                                name="number"
                                value={formData.number}
                                onChange={handleChange}
                                required
                            />
                            <label htmlFor="email">Email</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                            <label htmlFor="address">Address</label>
                            <input
                                type="text"
                                id="address"
                                name="address"
                                value={formData.address}
                                onChange={handleChange}
                                required
                            />
                            <button type="submit">Get Started</button>
                        </div>
                    </form>
                </div>
            </section>

            <div id="three-js-background"></div>

            <div className="useful">
                <h1>Why Your Business Needs an E-Commerce Website</h1>
                <section className="useful-section">
                    <div className="info-box">
                        <i className="fas fa-store"></i>
                        <h3>24/7 Sales</h3>
                        <p>An e-commerce website allows your business to sell products and services online around the clock, reaching customers regardless of time or location.</p>
                    </div>
                    <div className="info-box">
                        <i className="fas fa-globe"></i>
                        <h3>Global Reach</h3>
                        <p>Expand your market reach by selling to customers worldwide, breaking geographical barriers that limit traditional brick-and-mortar stores.</p>
                    </div>
                    <div className="info-box">
                        <i className="fas fa-credit-card"></i>
                        <h3>Convenient Shopping</h3>
                        <p>Provide customers with a convenient shopping experience with features like easy checkout, multiple payment options, and personalized recommendations.</p>
                    </div>
                    <div className="info-box">
                        <i className="fas fa-chart-line"></i>
                        <h3>Scalable Growth</h3>
                        <p>E-commerce websites can be scaled to accommodate growing inventory, increasing customer base, and expanding business needs.</p>
                    </div>
                </section>
            </div>

            <section class="process">
                <h1>Our E-Commerce Website Development Process</h1>
                <div class="process-container">
                    <div class="process-card">
                        <div class="process-number">1</div>
                        <i class="fas fa-search process-icon"></i>
                        <h2>Discovery</h2>
                        <p>Understand your business goals, target audience, and market needs to tailor the e-commerce strategy.</p>
                    </div>
                    <div class="process-card">
                        <div class="process-number">2</div>
                        <i class="fas fa-pencil-ruler process-icon"></i>
                        <h2>Design</h2>
                        <p>Create a user-friendly, visually appealing design that enhances the shopping experience.</p>
                    </div>
                    <div class="process-card">
                        <div class="process-number">3</div>
                        <i class="fas fa-code process-icon"></i>
                        <h2>Development</h2>
                        <p>Build the e-commerce site with essential features like product listings, cart functionality, and secure payment options.</p>
                    </div>
                    <div class="process-card">
                        <div class="process-number">4</div>
                        <i class="fas fa-cogs process-icon"></i>
                        <h2>Integration</h2>
                        <p>Integrate with third-party services such as payment gateways, shipping providers, and inventory management systems.</p>
                    </div>
                    <div class="process-card">
                        <div class="process-number">5</div>
                        <i class="fas fa-bug process-icon"></i>
                        <h2>Testing</h2>
                        <p>Test the website for functionality, performance, and security to ensure a smooth user experience.</p>
                    </div>
                    <div class="process-card">
                        <div class="process-number">6</div>
                        <i class="fas fa-rocket process-icon"></i>
                        <h2>Launch</h2>
                        <p>Deploy the site and monitor its performance to ensure it meets your business goals and provides an optimal user experience.</p>
                    </div>
                </div>
            </section>

            <div className="faq-container">
                <h1>Ecommerce Website Service - FAQ</h1>
                {[
                      {
                        question: "What services does 100Billion Tech offer for e-commerce websites?",
                        answer: "We offer a range of e-commerce services including custom website design and development, payment gateway integration, product catalog setup, inventory management, and ongoing support. Our team ensures that your e-commerce site is tailored to your business needs and goals."
                    },
                    {
                        question: "How long does it take to develop an e-commerce website?",
                        answer: "The development timeline for an e-commerce website depends on the complexity and features required. On average, it can take anywhere from 6 to 12 weeks from initial planning to launch. We provide a detailed timeline based on your specific requirements."
                    },
                    {
                        question: "Can you help with product and inventory management?",
                        answer: "Yes, we can integrate your e-commerce website with inventory management systems to streamline product management, track stock levels, and automate updates. This ensures that your inventory is accurately reflected on your website."
                    },
                    {
                        question: "Do you offer SEO services for e-commerce websites?",
                        answer: "Yes, we provide SEO services to improve your e-commerce website's visibility in search engine results. This includes keyword optimization, on-page SEO, technical SEO, and ongoing performance monitoring to drive organic traffic to your site."
                    },
                    {
                        question: "What payment methods can be integrated into the e-commerce site?",
                        answer: "We can integrate various payment methods into your e-commerce website, including credit/debit cards, PayPal, Stripe, and other payment gateways. This ensures that your customers have multiple payment options for a convenient shopping experience."
                    },
                    {
                        question: "How do you ensure the security of the e-commerce website?",
                        answer: "We implement robust security measures including SSL certificates, secure payment processing, and regular security updates to protect your e-commerce website from threats and ensure the safety of your customers' data."
                    },
                    {
                        question: "Can you help with ongoing maintenance and updates?",
                        answer: "Yes, we offer ongoing maintenance services to keep your e-commerce website updated, secure, and running smoothly. This includes software updates, security patches, and performance optimization."
                    },
                    {
                        question: "What should I prepare before starting the e-commerce website project?",
                        answer: "Before starting, you should prepare key details such as your business goals, target audience, product information, branding assets (logos, images), and any specific features or functionalities you need. This will help streamline the development process and ensure your website meets your expectations."
                    }
                ].map((faq, index) => (
                    <div className="faq-item" key={index}>
                        <div className="faq-question" onClick={(e) => toggleFaq(e.currentTarget)}>
                            {faq.question}
                            <i className="icon fas fa-chevron-down"></i>
                        </div>
                        <div className="faq-answer">{faq.answer}</div>
                    </div>
                ))}
            </div>
        </>
    );
}

// JS function for toggling FAQ
function toggleFaq(element) {
    const faqItems = document.querySelectorAll('.faq-item');

    faqItems.forEach(item => {
        if (item !== element.parentElement) {
            item.classList.remove('active');
        }
    });

    element.parentElement.classList.toggle('active');
}

export default ServicePage;
