import React, { useState } from "react";
import "../Components/CSS/Global.css";
import { toast } from "react-toastify";

const ResetPasswordModal = ({ isOpen, onClose }) => {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const makeApiCall = async (url, payload) => {
    setLoading(true);
    const response = await fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    });
    setLoading(false);
    return response;
  };

  const handleContinue = async () => {
    if (!/\S+@\S+\.\S+/.test(email)) {
      showToast("Please enter a valid email address.","error");
      return;
    }

    // Check if user exists
    const response = await makeApiCall("http://localhost:5000/check-user", { email });
    const data = await response.json();

    if (data.exists) {
      // Send OTP if user exists
      const otpResponse = await makeApiCall("http://localhost:5000/reset-send-otp", { email });
      const otpData = await otpResponse.json();
      if (otpResponse.ok) {
        showToast("OTP sent to your email.","success");
        setStep(2); // Move to verification step
      } else {
        setError(otpData.error || "Failed to send OTP. Please try again.");
      }
    } else {
      showToast("Invalid email. Please try again.","error");
    }
  };

  const handleSubmitCode = async () => {
    const response = await makeApiCall("http://localhost:5000/reset-verify-code", { email, otp: verificationCode });
    const data = await response.json();
    if (response.ok) {
      showToast("OTP verified successfully.","success");
      setStep(3); // Move to reset password step
    } else {
      setError(data.error || "Invalid OTP. Please try again.");
    }
};


  const handleResetPassword = async () => {
    if (newPassword !== confirmPassword) {
      showToast("Passwords do not match!","error");
      return;
    }
    if (newPassword.length < 8) {
      showToast("Password should be at least 8 characters long.","error");
      return;
    }

    const response = await makeApiCall("http://localhost:5000/reset-password", { email, password: newPassword });
    if (response.ok) {
      showToast("Password reset successfully!","success");
      onClose();
    } else {
      showToast("Error resetting password.","error");
    }
  };

  if (!isOpen) return null;

  const showToast = (message, type = "info") => {
    toast(message, {
      type,
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="modal-close" onClick={onClose}>
          <i className="fa-solid fa-xmark"></i>
        </button>
        {loading && <div className="loader">Loading...</div>}
        {error && <div className="error-message">{error}</div>}
        
        {step === 1 && (
          <div>
            <h2>Reset Password</h2>
            <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button onClick={handleContinue} disabled={loading}>Continue</button>
          </div>
        )}
        
        {step === 2 && (
          <div>
            <h2>Verification</h2>
            <input
              type="text"
              placeholder="Enter verification code"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
            />
            <button onClick={handleSubmitCode} disabled={loading}>Submit</button>
          </div>
        )}
        
        {step === 3 && (
          <div>
            <h2>Set New Password</h2>
            <input
              type="password"
              placeholder="New password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <input
              type="password"
              placeholder="Confirm password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <button onClick={handleResetPassword} disabled={loading}>Reset Password</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ResetPasswordModal;
