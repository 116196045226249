// Footer.js
import React from 'react';
import { Link } from 'react-router-dom';
import '../Components/CSS/Global.css'

const Footer = () => {
    return (
        <>
        <div className="call">
                <div className="card">
                    <div className="card-content">
                        <h2 className="card-title">Get in Touch with Us!</h2>
                        <p className="card-text">Have a question or need support? We're here to help! Drop us a message and we'll get back to you as soon as possible.</p>
                    </div>
                    <div className="card-btn">
                        <Link to="/contact" className="card-button">Contact Us</Link>
                    </div>
                </div>
            </div>
        <footer>
            <div className="footer-container">
                <div className="footer-section company-info">
                    <h4>About Company.</h4>
                    <p>
                        100Billion Tech is a leading technology solutions provider dedicated to transforming businesses
                        through innovative digital solutions.
                    </p>
                    <div className="social-icons">
                        <a href="#"><i className="fab fa-facebook-f"></i></a>
                        <a href="#"><i className="fab fa-twitter"></i></a>
                        <a href="#"><i className="fa-brands fa-linkedin-in"></i></a>
                    </div>
                </div>
                <div className="footer-section support">
                    <h4>Support.</h4>
                    <ul>
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/about">About Us</Link></li>
                        <li><Link to="/contact">Contact Us</Link></li>
                    </ul>
                </div>
                <div className="footer-section services">
                    <h4>Services.</h4>
                    <ul>
                        <li><Link to="/website-development">Website Development</Link></li>
                        <li><Link to="/application-development">Mobile Development</Link></li>
                        <li><Link to="/ecommerce">E-commerce Website</Link></li>
                        <li><Link to="/digital-marketing">Digital Marketing</Link></li>
                        <li><Link to="/google-ads">Google Ads</Link></li>
                        <li><Link to="/meta-ads">Meta Ads</Link></li>
                    </ul>
                </div>
                <div className="footer-section contact">
                    <h4>Contact.</h4>
                    <p><i className="fas fa-map-marker-alt"></i> 136, Kuber Complex, New Link Road Adheri West</p>
                    <p><i className="fas fa-phone"></i> +91 771-586-3833</p>
                    <p><i className="fas fa-envelope"></i> info@100billiontech.com</p>
                </div>
            </div>
            <div className="footer-bottom">
                <p>Copyright &copy; 100billion Tech 2024 All Rights Reserved</p>
            </div>
        </footer>
        </>
    );
};

export default Footer;
