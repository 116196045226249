import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from '../Components/img/icon_1.svg';
import FormImg from '../Components/img/Google_Ads.svg';

function ServicePage() {
    const [formData, setFormData] = useState({
        service: '',
        name: '',
        number: '',
        email: '',
        address: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch('http://localhost:5000/service-submit', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData),
                
            });

            if (response.ok) {
                console.log('Form submitted successfully');
            } else {
                console.log('Error submitting form');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };
    return (
        <>
            <div className="service-page-hero-main">
                <div className="service-page-hero-container">
                    {/* Update badge */}
                    <div className="service-page-hero-update-badge">Google Ads</div>

                    {/* Main content */}
                    <div className="service-heading">Business with Google Ads</div>
                    <div>Drive qualified traffic and increase conversions with expertly crafted Google Ads that reach your ideal audience at the right moment.</div>

                    <Link to="/contact">
                        <button className="service-button">Try It Now</button>
                    </Link>
                    <Link to="tel:+918433803555">
                        <button className="service-button2">+91 8433-803-555</button>
                    </Link>

                    {/* Logo */}
                    <div className="service-page-hero-logo">
                        <img src={logo} alt="Logo" />
                    </div>
                </div>
            </div>

            <section className="form-area">
                <div className="illustration">
                    <img src={FormImg} alt="Google Ads" />
                </div>
                <div className="form-container">
                    <form id="multiStepForm" onSubmit={handleSubmit}>

                        <div className="form-step active" id="formStep1">
                            <h2>Get Started Today</h2> <br />
                            <div className="error" id="formStep1Error"></div>
                            <select 
                            id="service" 
                            name="service" 
                            value={formData.service}
                            onChange={handleChange}
                            required>
                                <option value="">--Select Service--</option>
                                <option value="websiteDevelopment">Website Development</option>
                                <option value="ecommerceWebsite">E-commerce Website</option>
                                <option value="mobileDevelopment">Mobile Development</option>
                            </select>
                            <label htmlFor="name">Name</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                            <label htmlFor="number">Number</label>
                            <input
                                type="tel"
                                id="number"
                                name="number"
                                value={formData.number}
                                onChange={handleChange}
                                required
                            />
                            <label htmlFor="email">Email</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                            <label htmlFor="address">Address</label>
                            <input
                                type="text"
                                id="address"
                                name="address"
                                value={formData.address}
                                onChange={handleChange}
                                required
                            />
                            <button type="submit">Get Started</button>
                        </div>
                    </form>
                </div>
            </section>

            <div id="three-js-background"></div>

            <div className="useful google">
                <h1>Why Your Business Needs Google Ads</h1>
                <section className="useful-section">
                    <div className="info-box">
                        <i className="fas fa-search"></i>
                        <h3>Search Ads</h3>
                        <p>Text-based ads that appear on Google search results when users search for relevant keywords, driving targeted traffic to your website.</p>
                    </div>
                    <div className="info-box">
                        <i className="fas fa-google"></i>
                        <h3>Display Ads</h3>
                        <p>Visual banner ads shown on Google Display Network sites, reaching a broader audience across millions of websites and apps.</p>
                    </div>
                    <div className="info-box">
                        <i className="fas fa-video"></i>
                        <h3>Video Ads</h3>
                        <p>Engaging video ads that run on YouTube and across the Google Display Network, designed to capture attention and enhance brand storytelling.</p>
                    </div>
                    <div className="info-box">
                        <i className="fas fa-shopping-cart"></i>
                        <h3>Shopping Ads</h3>
                        <p>Product-based ads that showcase your inventory directly in search results, allowing users to view images, prices, and descriptions before clicking.</p>
                    </div>

                    <div className="info-box">
                        <i className="fas fa-mobile-alt"></i>
                        <h3>App Campaigns </h3>
                        <p>Promote your mobile app across Google Search, Play Store, YouTube, and the Display Network to drive downloads and user engagement. </p>
                    </div>

                    <div className="info-box">
                        <i className="fas fa-chart-line"></i>
                        <h3>Remarketing Ads </h3>
                        <p>Target previous visitors with tailored ads to bring them back to your website, increasing conversions by reminding them of products they viewed. </p>
                    </div>

                    <div className="info-box">
                        <i className="fas fa-map-marker-alt"></i>
                        <h3>Local Service Ads </h3>
                        <p>Ads that connect local service providers with nearby customers, featuring business information and customer reviews to enhance trust. </p>
                    </div>

                    <div className="info-box">
                        <i className="fas fa-chart-line"></i>
                        <h3>Performance Max Campaigns </h3>
                        <p>A goal-based campaign type that optimizes performance across all Google Ads inventory, using machine learning to maximize results. </p>
                    </div>

                    <div className="info-box">
                        <i className="fas fa-video"></i>
                        <h3>Discovery Ads </h3>
                        <p>Visually rich ads shown on Google Discover, YouTube, and Gmail, designed to engage users with personalized content as they browse. </p>
                    </div>

                    <div className="info-box">
                        <i className="fas fa-desktop"></i>
                        <h3>Smart Campaigns </h3>
                        <p>Automated campaigns designed for small businesses, allowing users to set their goals while Google manages ad placements and targeting. </p>
                    </div>

                    <div className="info-box">
                        <i className="fas fa-chart-line"></i>
                        <h3>Lead Form Extensions </h3>
                        <p>Collect leads directly from your ads by integrating lead forms, making it easy for potential customers to submit their information. </p>
                    </div>

                    <div className="info-box">
                        <i className="fas fa-code"></i>
                        <h3>Ad Extensions </h3>
                        <p>Additional information added to your ads (like phone numbers, site links, or location) that improve visibility and engagement. </p>
                    </div>
                </section>
            </div>

            <section className="process">
                <h1>Our Google Ads Management Process</h1>
                <div className="process-container">
                    <div className="process-card">
                        <div className="process-number">1</div>
                        <i className="fas fa-search process-icon"></i>
                        <h2>Research</h2>
                        <p>Conduct market research and competitor analysis to understand your target audience and keywords.</p>
                    </div>
                    <div className="process-card">
                        <div className="process-number">2</div>
                        <i className="fas fa-cogs process-icon"></i>
                        <h2>Strategy</h2>
                        <p>Develop a customized Google Ads strategy tailored to your business goals and budget.</p>
                    </div>
                    <div className="process-card">
                        <div className="process-number">3</div>
                        <i className="fas fa-pen process-icon"></i>
                        <h2>Creation</h2>
                        <p>Create compelling ad copy and design, including text ads, display ads, and video ads as needed.</p>
                    </div>
                    <div className="process-card">
                        <div className="process-number">4</div>
                        <i className="fas fa-tachometer-alt process-icon"></i>
                        <h2>Launch</h2>
                        <p>Set up and launch your Google Ads campaigns, ensuring they are properly configured for optimal performance.</p>
                    </div>
                    <div className="process-card">
                        <div className="process-number">5</div>
                        <i className="fas fa-chart-bar process-icon"></i>
                        <h2>Monitoring</h2>
                        <p>Monitor the performance of your campaigns in real-time, adjusting bids and targeting as needed.</p>
                    </div>
                    <div className="process-card">
                        <div className="process-number">6</div>
                        <i className="fas fa-sync-alt process-icon"></i>
                        <h2>Optimization</h2>
                        <p>Continuously optimize your ads based on performance data to improve results and maximize ROI.</p>
                    </div>
                </div>
            </section>


            <div className="faq-container">
                <h1>Google Ads Service - FAQ</h1>
                {[
                   {
                    question: "What services does 100Billion Tech offer for Google Ads?",
                    answer: "We offer comprehensive Google Ads management services, including campaign setup, ad creation, keyword research, and ongoing optimization. Our team focuses on driving high-quality traffic and maximizing your ad spend efficiency."
                },
                {
                    question: "How much does it cost to run Google Ads?",
                    answer: "The cost of running Google Ads depends on various factors including keywords, competition, and your budget. You can set a daily or monthly budget to control your spending. We can help you develop a cost-effective strategy that aligns with your goals."
                },
                {
                    question: "How do you measure the success of a Google Ads campaign?",
                    answer: "Success is measured through various metrics including click-through rates (CTR), conversion rates, cost per click (CPC), and return on ad spend (ROAS). We use these metrics to evaluate and optimize your campaign’s performance."
                },
                {
                    question: "How long does it take to see results from Google Ads?",
                    answer: "Results can be seen almost immediately after launching your campaigns, with initial data on performance available within a few days. However, optimizing and achieving the best results may take a few weeks."
                },
                {
                    question: "Can you help with both search and display ads?",
                    answer: "Yes, we can manage both search ads and display ads. Search ads appear on Google search results, while display ads appear on websites across the Google Display Network. We’ll help you choose the right mix based on your goals."
                },
                {
                    question: "Do you offer Google Ads consulting services?",
                    answer: "Yes, we offer Google Ads consulting services to help you develop a strategy, optimize your existing campaigns, and provide insights to improve your ad performance."
                },
                {
                    question: "How do you handle keyword research and selection?",
                    answer: "We conduct thorough keyword research to identify relevant and high-performing keywords for your campaigns. This includes analyzing search volume, competition, and relevance to ensure your ads reach the right audience."
                },
                {
                    question: "Can you help with ad copy and design?",
                    answer: "Yes, we handle ad copywriting and design to create compelling and effective ads that attract clicks and conversions. Our goal is to ensure your ads are visually appealing and message-driven."
                },
                {
                    question: "What should I expect during the onboarding process?",
                    answer: "During onboarding, we’ll review your business goals, target audience, and current advertising efforts. We’ll then develop a tailored Google Ads strategy and set up your campaigns for optimal performance."
                }
                ].map((faq, index) => (
                    <div className="faq-item" key={index}>
                        <div className="faq-question" onClick={(e) => toggleFaq(e.currentTarget)}>
                            {faq.question}
                            <i className="icon fas fa-chevron-down"></i>
                        </div>
                        <div className="faq-answer">{faq.answer}</div>
                    </div>
                ))}
            </div>
        </>
    );
}

// JS function for toggling FAQ
function toggleFaq(element) {
    const faqItems = document.querySelectorAll('.faq-item');

    faqItems.forEach(item => {
        if (item !== element.parentElement) {
            item.classList.remove('active');
        }
    });

    element.parentElement.classList.toggle('active');
}

export default ServicePage;
