import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import LoginModal from './LoginModal';
import './CSS/Global.css';
import logo from '../Components/img/100BILLION_TECH_name.png';
import { toast } from "react-toastify";

const Header = () => {
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false); // State for login modal
    const [isLogoutModalOpen, setLogoutModalOpen] = useState(false); // State for logout confirmation modal
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userEmail, setUserEmail] = useState('');

    useEffect(() => {
        // Retrieve login data from localStorage on mount
        const savedUserEmail = localStorage.getItem('userEmail');
        if (savedUserEmail) {
            setUserEmail(savedUserEmail);
            setIsLoggedIn(true);
        }
    }, []);

    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
    };

    const toggleDropdown = () => {
        setDropdownOpen(!isDropdownOpen);
    };

    const handleLoginSuccess = (email) => {
        setUserEmail(email);
        setIsLoggedIn(true);
        setModalOpen(false);
        // Store user data in localStorage
        localStorage.setItem('userEmail', email);
    };

    const handleLogout = () => {
        setIsLoggedIn(false);
        setUserEmail('');
        setLogoutModalOpen(false);
        localStorage.removeItem('userEmail');
        showToast("Logout successfully", "success");
    };

    const showToast = (message, type = "info") => {
        toast(message, {
          type,
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      };

    return (
        <header className="header">
            <div className="header-ham-logo">
                {/* Hamburger icon for mobile menu */}
                <button className="hamburger" onClick={toggleMenu}>
                    <i className={`fas ${isMenuOpen ? 'fa-times' : 'fa-bars'}`}></i>
                </button>

                {/* Logo */}
                <div className="header-logo">
                    <Link to="/">
                        <img src={logo} alt="Logo" className="logo-img" />
                    </Link>
                </div>
            </div>

            {/* Navigation menu */}
            <nav className={`header-nav ${isMenuOpen ? 'active' : ''}`}>
                <ul className="nav-links">
                    <li><Link to="/" onClick={() => setMenuOpen(false)}>Home</Link></li>
                    <li className="dropdown" onMouseEnter={() => window.innerWidth > 768 && toggleDropdown()} onMouseLeave={() => window.innerWidth > 768 && toggleDropdown()}>
                        <Link to="#" className="dropdown-toggle" onClick={() => setDropdownOpen(!isDropdownOpen)}>Our Service</Link>
                        {isDropdownOpen && (
                            <ul className="dropdown-menu">
                                <li><Link to="/website-development" onClick={() => setMenuOpen(false)}>Website Development</Link></li>
                                <li><Link to="/application-development" onClick={() => setMenuOpen(false)}>Application Development</Link></li>
                                <li><Link to="/ecommerce" onClick={() => setMenuOpen(false)}>E-commerce Website</Link></li>
                                <li><Link to="/google-ads" onClick={() => setMenuOpen(false)}>Google Ads</Link></li>
                                <li><Link to="/meta-ads" onClick={() => setMenuOpen(false)}>Meta Ads</Link></li>
                                <li><Link to="/digital-marketing" onClick={() => setMenuOpen(false)}>Digital Marketing</Link></li>
                            </ul>
                        )}
                    </li>
                    {/* <li><Link to="/web-store" onClick={() => setMenuOpen(false)}>Web Store</Link></li> */}
                    <li><Link to="/about" onClick={() => setMenuOpen(false)}>About</Link></li>
                    <li><Link to="/contact" onClick={() => setMenuOpen(false)}>Contact</Link></li>
                </ul>
            </nav>

            {/* Login/Profile section */}
            {/* <div className="header-login">
                {isLoggedIn ? (
                    <div className="profile" onClick={toggleDropdown}>
                        <img src="https://picsum.photos/40/40" alt="Profile" className="profile-pic" />
                        <span className="profile-name">
                            {userEmail.length > 8 ? `${userEmail.slice(0, 8)}...` : userEmail}
                        </span>
                        <i className={`fas fa-chevron-down ${isDropdownOpen ? 'open' : ''}`}></i>
                        {isDropdownOpen && (
                            <div className="profile-dropdown">
                                <p className="full-profile-name">{userEmail}</p>
                                <Link to="/profile" onClick={() => setMenuOpen(false)}><i className="fa-solid fa-user"></i>Profile</Link>
                                <button onClick={() => setLogoutModalOpen(true)}><i className="fa-solid fa-right-from-bracket"></i>Logout</button>
                            </div>
                        )}
                    </div>
                ) : (
                    !isModalOpen && ( // Only show the login button when modal is not open
                        <button className="login-button" onClick={() => setModalOpen(true)}>Login</button>
                    )
                )}
            </div>

            <LoginModal isOpen={isModalOpen} onClose={() => setModalOpen(false)} onLoginSuccess={handleLoginSuccess} />

            {isLogoutModalOpen && (
                <div className="logout-modal">
                    <div className="logout-modal-content">
                        <p>Are you sure you want to log out?</p>
                        <button onClick={() => setLogoutModalOpen(false)}>Cancel</button>
                        <button onClick={handleLogout}>Logout</button>
                    </div>
                </div>
            )} */}
        </header>
    );
};

export default Header;
