import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from '../Components/img/icon_1.svg';
import FormImg from '../Components/img/apps.svg';

function ServicePage() {
    const [formData, setFormData] = useState({
        "form_type": "service",
        service: '',
        name: '',
        number: '',
        email: '',
        address: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch('http://localhost:5000/service-submit', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData),
                
            });

            if (response.ok) {
                console.log('Form submitted successfully');
            } else {
                console.log('Error submitting form');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };
    return (
        <>
            <div className="service-page-hero-main">
                <div className="service-page-hero-container">
                    {/* Update badge */}
                    <div className="service-page-hero-update-badge">Application Development</div>

                    {/* Main content */}
                    <div className="service-heading">Bring your ideas to reality</div>
                    <div>Turn your vision into reality with our expert app development services, delivering innovative, custom solutions tailored to your needs.</div>

                    <Link to="/contact">
                        <button className="service-button">Try It Now</button>
                    </Link>
                    <Link to="tel:+918433803555">
                        <button className="service-button2">+91 8433-803-555</button>
                    </Link>

                    {/* Logo */}
                    <div className="service-page-hero-logo">
                        <img src={logo} alt="Logo" />
                    </div>
                </div>
            </div>

            <section className="form-area">
                <div className="illustration">
                    <img src={FormImg} alt="illustration" />
                </div>
                <div className="form-container">
                    <form id="multiStepForm" onSubmit={handleSubmit}>
                        <div className="form-step active" id="formStep1">
                            <h2>Get Started Today</h2> <br />
                            <div className="error" id="formStep1Error"></div>
                            <select 
                            id="service" 
                            name="service" 
                            value={formData.service}
                            onChange={handleChange}
                            required>
                                <option value="">--Select Service--</option>
                                <option value="websiteDevelopment">Website Development</option>
                                <option value="ecommerceWebsite">E-commerce Website</option>
                                <option value="mobileDevelopment">Mobile Development</option>
                            </select>
                            <label htmlFor="name">Name</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                            <label htmlFor="number">Number</label>
                            <input
                                type="tel"
                                id="number"
                                name="number"
                                value={formData.number}
                                onChange={handleChange}
                                required
                            />
                            <label htmlFor="email">Email</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                            <label htmlFor="address">Address</label>
                            <input
                                type="text"
                                id="address"
                                name="address"
                                value={formData.address}
                                onChange={handleChange}
                                required
                            />
                            <button type="submit">Get Started</button>
                        </div>
                    </form>
                </div>
            </section>

            <div id="three-js-background"></div>
            <div className="useful">
                <h1>Why Every Business Needs a Mobile App</h1>
                <section className="useful-section">
                    <div className="info-box">
                        <i className="fas fa-mobile-alt"></i>
                        <h3>Enhanced Accessibility</h3>
                        <p>Mobile apps provide customers with access to your business anytime, anywhere, directly from their smartphones.</p>
                    </div>
                    <div className="info-box">
                        <i className="fas fa-cogs"></i>
                        <h3>Improved Functionality</h3>
                        <p>Mobile apps can leverage device features like GPS, cameras, and accelerometers to offer enhanced functionality.</p>
                    </div>
                    <div className="info-box">
                        <i className="fas fa-users"></i>
                        <h3>Customer Engagement</h3>
                        <p>Engage with users through push notifications, in-app messaging, and personalized experiences.</p>
                    </div>
                    <div className="info-box">
                        <i className="fas fa-chart-bar"></i>
                        <h3>Analytics</h3>
                        <p>Gain valuable insights into user behavior and app performance through built-in analytics tools.</p>
                    </div>
                </section>
            </div>

            <section class="process">
                <h1>Our Mobile App Development Process</h1>
                <div class="process-container">
                    <div class="process-card">
                        <div class="process-number">1</div>
                        <i class="fas fa-project-diagram process-icon"></i>
                        <h2>Planning</h2>
                        <p>Define the scope, goals, and timeline of the app development project.</p>
                    </div>
                    <div class="process-card">
                        <div class="process-number">2</div>
                        <i class="fas fa-pencil-ruler process-icon"></i>
                        <h2>Design</h2>
                        <p>Create wireframes and mockups of the app’s user interface and experience.</p>
                    </div>
                    <div class="process-card">
                        <div class="process-number">3</div>
                        <i class="fas fa-code process-icon"></i>
                        <h2>Development</h2>
                        <p>Build the app using the latest technologies for iOS and Android platforms.</p>
                    </div>
                    <div class="process-card">
                        <div class="process-number">4</div>
                        <i class="fas fa-bug process-icon"></i>
                        <h2>Testing</h2>
                        <p>Test the app for usability, performance, and bugs on various devices and operating systems.</p>
                    </div>
                    <div class="process-card">
                        <div class="process-number">5</div>
                        <i class="fas fa-rocket process-icon"></i>
                        <h2>Deployment</h2>
                        <p>Launch the app on the App Store and Google Play, and monitor its performance.</p>
                    </div>
                    <div class="process-card">
                        <div class="process-number">6</div>
                        <i class="fas fa-chart-line process-icon"></i>
                        <h2>Maintenance</h2>
                        <p>Update and maintain the app to ensure compatibility with new devices and OS versions.</p>
                    </div>
                </div>
            </section>

            <div className="faq-container">
                <h1>Application Development Service - FAQ</h1>
                {[
                      {
                        question: "What services does 100Billion Tech provide in mobile app development?",
                        answer: "We offer a wide range of mobile app development services, including native app development for iOS and Android, cross-platform development, UI/UX design, and app maintenance. Our team is dedicated to creating high-quality, user-friendly mobile apps tailored to your business needs."
                    },
                    {
                        question: "How long does it take to develop a mobile app?",
                        answer: "The timeline for mobile app development depends on the complexity and specific requirements of the app. On average, a standard app can take 8-12 weeks from concept to launch, while more complex apps with advanced features may take 16-24 weeks."
                    },
                    {
                        question: "Do you offer ongoing maintenance and support for mobile apps?",
                        answer: "Yes, 100Billion Tech provides ongoing maintenance and support services to keep your app updated, secure, and running smoothly. We offer various support packages to suit your needs."
                    },
                    {
                        question: "Can you help with app store optimization and marketing?",
                        answer: "Absolutely! Our team can assist with app store optimization (ASO) to improve your app’s visibility and rankings. We also offer marketing services including social media promotion, content marketing, and targeted advertising."
                    },
                    {
                        question: "What technologies do you use for mobile app development?",
                        answer: "We use a variety of technologies for mobile app development, including Swift and Objective-C for iOS, Kotlin and Java for Android, and cross-platform frameworks like React Native and Flutter. We choose the best tools based on your app’s requirements."
                    },
                    {
                        question: "Do you develop custom mobile applications?",
                        answer: "Yes, we specialize in developing custom mobile applications tailored to your business needs. Whether you need a custom app for internal use, a unique consumer-facing app, or anything in between, our team can create a solution that meets your requirements."
                    },
                    {
                        question: "How much does it cost to develop a mobile app?",
                        answer: "The cost of developing a mobile app depends on various factors such as complexity, features, design, and timeline. We provide custom quotes based on your specific needs. Contact us for a detailed proposal."
                    },
                    {
                        question: "Will my app work on both iOS and Android devices?",
                        answer: "Yes, we can develop apps for both iOS and Android platforms. Depending on your needs, we can create native apps for each platform or use cross-platform development tools to build a single app that works on both."
                    },
                    {
                        question: "Can you integrate third-party services into my mobile app?",
                        answer: "Yes, we can integrate various third-party services and APIs into your mobile app, such as payment gateways, social media platforms, analytics tools, and more. Our team ensures seamless integration to enhance your app’s functionality."
                    },
                    {
                        question: "What is your process for starting a new mobile app project?",
                        answer: "Our process begins with an initial consultation to understand your app’s goals and requirements. We then proceed with planning, design, development, testing, and launch. Throughout the project, we maintain close communication with you to ensure your vision is realized."
                    }
                    // More FAQs...
                ].map((faq, index) => (
                    <div className="faq-item" key={index}>
                        <div className="faq-question" onClick={(e) => toggleFaq(e.currentTarget)}>
                            {faq.question}
                            <i className="icon fas fa-chevron-down"></i>
                        </div>
                        <div className="faq-answer">{faq.answer}</div>
                    </div>
                ))}
            </div>
        </>
    );
}

// JS function for toggling FAQ
function toggleFaq(element) {
    const faqItems = document.querySelectorAll('.faq-item');

    faqItems.forEach(item => {
        if (item !== element.parentElement) {
            item.classList.remove('active');
        }
    });

    element.parentElement.classList.toggle('active');
}

export default ServicePage;
