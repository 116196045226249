import '../Components/CSS/Global.css';
import mission from './img/1.png'
import vision from './img/2.png'
import logo from './img/100BILLION_LOGO.png'
import CEO from './img/CEO.png'

const About = () => {
    return (
        <>
            <div className="about-page">
                <div className="hero">
                    <h1>About 100Billion Tech</h1>
                </div>
                <div className="about-section">
                    <img src={CEO} alt="Ankit Gupta" />
                        <div className="content">
                            <h2>Ankit Gupta</h2>
                            <p>Ankit Gupta, the visionary behind 100Billion Tech, embarked on this entrepreneurial journey in 2016
                                with a deep-seated passion for technology and a clear vision of how it could transform businesses.
                                With a background in software engineering and a knack for innovative problem-solving, Ankit has
                                successfully led the company to become a trusted name in the tech industry.</p>
                            <p>Ankit believes in a hands-on approach to leadership, where understanding the intricacies of every
                                project is crucial. This mindset has cultivated a culture of excellence within 100Billion Tech,
                                where every team member is encouraged to push the boundaries of what's possible. Under Ankit's
                                guidance, the company has delivered numerous successful projects that have not only met but exceeded
                                client expectations.</p>
                        </div>
                </div>

                <div className="about-section">
                    <div className="content">
                        <h2>100Billion Tech</h2>
                        <p>100Billion Tech was founded in 2016 with the aim of revolutionizing the way businesses approach
                            technology. Our company specializes in a wide range of services, including web development, mobile
                            app creation, and comprehensive digital marketing strategies. What sets us apart is our ability to
                            tailor our services to the unique needs of each client, ensuring that our solutions are not only
                            effective but also aligned with their business goals.</p>
                        <p>Over the years, 100Billion Tech has grown into a robust team of professionals, each bringing their
                            own expertise to the table. Our developers, designers, and digital marketers work closely together
                            to create seamless, innovative solutions that drive business success. We pride ourselves on staying
                            at the cutting edge of technology, constantly evolving to meet the changing demands of the industry.
                        </p>
                    </div>
                    <img src={logo} alt="100Billion Tech" />
                </div>

                <div className="about-section">
                    <img src={mission} alt="Mission" />
                        <div className="content">
                            <h2>Our Mission</h2>
                            <p>Our mission at 100Billion Tech is to empower businesses by delivering innovative and reliable
                                technology solutions that foster growth and drive success. We are committed to pushing the
                                boundaries of technology to create impactful results that make a difference in our clients’ lives
                                and businesses.</p>
                            <p>We believe in building long-term partnerships with our clients, understanding their challenges, and
                                working collaboratively to develop solutions that deliver measurable results. Our mission is not
                                just about providing services; it's about creating value and making a positive impact on the
                                industries we serve.</p>
                        </div>
                </div>

                <div className="about-section">
                    <div className="content">
                        <h2>Our Vision</h2>
                        <p>At 100Billion Tech, our vision is to become a global leader in the technology services sector,
                            renowned for our dedication to quality, creativity, and customer satisfaction. We aspire to shape
                            the future of technology by continuously innovating and delivering unparalleled solutions to our
                            clients worldwide.</p>
                        <p>Our vision is to create a world where technology seamlessly integrates into every aspect of business,
                            enhancing productivity, driving growth, and creating new opportunities. We see ourselves as a
                            catalyst for change, helping businesses navigate the complexities of the digital age and achieve
                            their full potential.</p>
                    </div>
                    <img src={vision} alt="Vision" />
                </div>
            </div>
        </>
    );
};

export default About