import React, { useState } from 'react';
import '../Components/CSS/Global.css';
import ContactPng from './img/contactme.png'

const Contact = () => {
    const [formData, setFormData] = useState({
        contactName: '',
        contactEmail: '',
        contactMessage: '',
        contactService: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch('http://localhost:5000/contact-submit', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                console.log('Form submitted successfully');
            } else {
                console.log('Error submitting form');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <>
            <div className="contact-page">
                <div className="hero">
                    <h1>Contact Us</h1>
                </div>

                <div className="contact-section">
                    <div className="illustration">
                        <img src={ContactPng} alt="Contact Illustration" />
                    </div>
                    <div className="form-container">
                        <form onSubmit={handleSubmit}>
                            <input
                                type="text"
                                name="contactName"
                                placeholder="Your Name"
                                required
                                value={formData.contactName}
                                onChange={handleChange}
                            />
                            <input
                                type="email"
                                name="contactEmail"
                                placeholder="Your Email"
                                required
                                value={formData.contactEmail}
                                onChange={handleChange}
                            />
                            <select
                                name="contactService"
                                required
                                value={formData.contactService}
                                onChange={handleChange}
                            >
                                <option value="" disabled>Select a Service</option>
                                <option value="website-development">Website Development</option>
                                <option value="mobile-development">Mobile Development</option>
                                <option value="ecommerce-website">Ecommerce Website</option>
                                <option value="digital-marketing">Digital Marketing</option>
                                <option value="google-ads">Google Ads</option>
                                <option value="meta-ads">Meta Ads</option>
                            </select>
                            <textarea
                                name="contactMessage"
                                rows="5"
                                placeholder="Your Message"
                                required
                                value={formData.contactMessage}
                                onChange={handleChange}
                            ></textarea>
                            <button type="submit">
                                <i className="fas fa-paper-plane"></i> Send Message
                            </button>
                        </form>
                    </div>
                </div>

                <div className="location">
                    <h3>Our Location</h3>
                    <p><i className="fas fa-map-marker-alt"></i> 136, Kuber Complex, New Link Road Adheri West</p>
                    <p><a href="tel:+918433803555"><i className="fas fa-phone"></i> +91 8433-803-555</a></p>
                    <p><a href="tel:+917715863833"><i className="fas fa-phone"></i> +91 7715-863-833</a></p>
                    <p><a href="mailto:info@100billiontech.com"><i className="fas fa-envelope"></i> info@100billiontech.com</a></p>
                </div>
            </div>
        </>
    );
};

export default Contact;
