import React from "react";
import { Link } from "react-router-dom";
import '../Components/CSS/Global.css';

const WebStore = () => {
    return (
        <>
            <div className="software-card-container">
                <div className="software-card">
                    <i className="fas fa-shopping-cart fa-3x"></i>
                    <h2>Ecommerce Software</h2>
                    <p>Manage your online store effortlessly.</p>
                    <Link to="/ecommerce-price" className="get-started-button">Get Started</Link>
                </div>

                <div className="software-card">
                    <i className="fas fa-users fa-3x"></i>
                    <h2>Multi Vendor</h2>
                    <p>Unified platform for vendor management.</p>
                    <Link to="/vendor-price" className="get-started-button">Get Started</Link>
                </div>

                <div className="software-card">
                    <i className="fas fa-tv fa-3x"></i>
                    <h2>OTT Platform</h2>
                    <p>Deliver content seamlessly to your audience.</p>
                    <Link to="/ottplatform-price" className="get-started-button">Get Started</Link>
                </div>

                <div className="software-card">
                    <i className="fas fa-clipboard-list fa-3x"></i>
                    <h2>CRM Software</h2>
                    <p>Enhance your customer relationship management.</p>
                    <Link to="/crm-price" className="get-started-button">Get Started</Link>
                </div>

                <div className="software-card">
                    <i className="fas fa-users fa-3x"></i>
                    <h2>Staff Management</h2>
                    <p>Efficiently manage your team and resources.</p>
                    <Link to="/staff-price" className="get-started-button">Get Started</Link>
                </div>

                <div className="software-card">
                    <i className="fas fa-sign-in-alt fa-3x"></i>
                    <h2>Login/Logout</h2>
                    <p>Streamlined authentication for users.</p>
                    <Link to="/login-price" className="get-started-button">Get Started</Link>
                </div>
            </div>
        </>
    );
};

export default WebStore;
